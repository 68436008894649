<template>
  <div class="table-container">
    <table class="table table-custom table-borderless table-custom-responsive mb-0">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Empresa</th>
          <th scope="col">Fecha de subida</th>
          <th scope="col" class="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody v-show="documentos.length != 0">
        <tr :class="{ 'disabled': documento.id_estado_fk == 3 }"
            v-for="(documento, i) in documentos"
            :key="i"
          >
            <td class="th th-nombre">
              {{ documento.nombre }}
            </td>
            <td class="th th-empresa">
              {{ documento.categoria[0].nombre }}
            </td>
            <td class="th th-fecha-subida">
              {{ documento.fecha_creacion }}
            </td>
            <td class="th th-accion text-center">
              <div 
                    class="dropdown dropdown-custom d-flex justify-content-center">
                    <button 
                      class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                      type="button" 
                      data-bs-toggle="dropdown">
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </button>
                  <ul 
                    class="dropdown-menu"
                    v-click-outside="outOption">
                    <li>
                      <a
                        href="javascript:"
                        class="dropdown-item" 
                        @click="disableDocument(documento.id_documento, documento.id_estado_fk)">
                        <font-awesome-icon
                          class="pe-2"
                          :class="documento.id_estado_fk == '1' ? 'color-red' : 'color-secondary'"
                          :icon="documento.id_estado_fk == '1' ?  ['fas', 'ban']:['fas', 'check']"/>         
                          {{ documento.id_estado_fk == "1" ? "Deshabilitar" : "Habilitar" }}
                      </a>
                    </li>
                    <li>
                      <a 
                        class="dropdown-item" 
                        @click="viewDocument(documento.url_documento)"
                      >
                        <font-awesome-icon
                          class="pe-2 color-main"
                          icon="eye"/>
                        Ver documento
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:"
                        class="dropdown-item"
                        @click="editDocument(documento.id_documento)">
                        <font-awesome-icon
                          class="pe-2 color-brown"
                          icon="pen"/>
                        Editar
                    </a>
                    </li>
                    <li>
                      <a
                        href="javascript:"
                        class="dropdown-item"
                        @click="deleteDocument(documento.id_documento)">
                        <font-awesome-icon
                          class="pe-2 color-red"
                          icon="trash-alt"/>
                        Eliminar
                    </a>
                    </li>
                  </ul>
                </div>
            </td>
        </tr>
      </tbody>
    </table>
    <div 
      v-if="documentos.length == 0 && search"
      class="alert alert-info mt-3 mb-0">
      <font-awesome-icon class="pe-2" icon="circle-info"/>
        No se han encontrado resultados
    </div>
    <div
      v-if="documentos.length == 0 && !search"
      class="nodata-section__wrapper">
      <img
        class="nodata-section__img"
        src="@/assets/img/utilities/question_face.svg"
        alt=""
      />
      <p class="nodata-section__text">Aún no se han registrado organigramas</p>
      <div class="nodata-section__button-wrapper">
        <button class="nodata-section__button">+ Agregar organigrama</button>
      </div>
    </div>
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Spinner v-if="show_spinner" />

<!--     <section class="table-section">
      <table class="table-section__table">
        <thead class="table-section__table-head sticky-top">
          <tr class="table-section__tr-head">
            <th class="table-section__data-head--name">Nombre</th>
            <th class="table-section__data-head">Empresa</th>
            <th class="table-section__data-head--date">Fecha de subida</th>
            <th class="table-section__data-head--actions">
              <div class="acciones">Acciones</div>
            </th>
          </tr>
        </thead>
        <tbody
          v-show="documentos.length != 0 && search == false"
          class="table-section__table-body"
        >
          <tr
            :class="{
              'table-section__table-row--disabled': documento.id_estado_fk == 3,
              'table-section__table-row': documento.id_estado_fk == 1,
            }"
            v-for="(documento, i) in documentos"
            :key="i"
          >
            <td class="table-section__table-data text-start ps-3">
              {{ documento.nombre }}
            </td>
            <td class="table-section__table-data">
              {{ documento.categoria[0].nombre }}
            </td>
            <td class="table-section__table-data">
              {{ documento.fecha_creacion }}
            </td>
            <td class="table-section__table-data--actions">
              <div class="table-section__dots">
                <img
                  @click="openOption(documento.id_documento, documento)"
                  src="@/assets/img/qiyab.png"
                  height="15"
                  alt=""
                  class="table-section__dots--img"
                />
                <div
                  class="options-encuesta"
                  v-if="option == documento.id_documento"
                  v-click-outside="outOption"
                >
                  <div
                    @click="viewDocument(documento.url_documento)"
                    class="options-encuesta__option ps-2"
                  >
                    <img
                      class="inscripciones-busqueda__option-img"
                      src="@/assets/img/Vista_previa.svg"
                      alt=""
                    />
                    <div class="options-encuesta__option-text">Ver documento</div>
                  </div>
                  <div
                    @click="editDocument(documento.id_documento)"
                    class="options-encuesta__option ps-2"
                  >
                    <img
                      class="inscripciones-busqueda__option-img"
                      src="@/assets/img/Editar.svg"
                      alt=""
                    />
                    <div class="options-encuesta__option-text">Editar</div>
                  </div>
                  <div
                    @click="disableDocument(documento.id_documento, documento.id_estado_fk)"
                    class="options-encuesta__option ps-2"
                  >
                    <img
                      v-if="documento.id_estado_fk == 1"
                      class="inscripciones-busqueda__option-img"
                      src="@/assets/img/Deshabilitar.svg"
                      alt=""
                    />
                    <img
                      v-else
                      class="inscripciones-busqueda__option-img"
                      src="@/assets/img/Habilitar.svg"
                      alt=""
                    />
                    <div
                      v-if="documento.id_estado_fk == 1"
                      class="options-encuesta__option-text"
                    >
                      Deshabilitar
                    </div>
                    <div v-else class="options-encuesta__option-text">
                      Habilitar
                    </div>
                  </div>
                  <div
                    @click="deleteDocument(documento.id_documento)"
                    class="options-encuesta__option ps-2"
                  >
                    <img
                      class="inscripciones-busqueda__option-img"
                      src="@/assets/img/Eliminar.svg"
                      alt=""
                    />
                    <div class="options-encuesta__option-text">Eliminar</div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-show="documentos.length == 0 && search == true"
        class="nodata-section__wrapper"
      >
        <p class="nodata-section__text">No se han encontrado resultados</p>
      </div>
      <div
        v-show="documentos.length == 0 && search == false"
        class="nodata-section__wrapper"
      >
        <img
          class="nodata-section__img"
          src="@/assets/img/utilities/question_face.svg"
          alt=""
        />
        <p class="nodata-section__text">Aún no se han registrado organigramas</p>
        <div class="nodata-section__button-wrapper">
          <button class="nodata-section__button">+ Agregar organigrama</button>
        </div>
      </div>
      <Question
        v-if="open_question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelQuestion"
        @accept="acceptQuestion"
      />
      <Spinner v-if="show_spinner" />
    </section> -->
  </div>

</template>
<script>
import Question from "../Modales/Question.vue";
import Spinner from "../Spinner.vue";
import { mapActions } from "vuex";
export default {
  components: {
    Question,
    Spinner,
  },
  props: {
    documentos: {
      type: Array,
    },
    search: {
      type: Boolean,
    },   
  },
  data() {
    return {
      option: "",
      open_question_modal: false,
      question_modal_msg: "",
      show_spinner: false,
    };
  },
  methods: {
    ...mapActions("organigramaModule", ["cambiaEstadoOrganigrama"]),
    openOption(id) {
      if (this.option == id) {
        this.outOption;
      } else {
        setTimeout(() => {
          this.option = id;
        }, 10);
      }
    },
    // Cierra opciones
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
    viewDocument(url_) {
      window.open(url_, '_blank');
    },
    editDocument(id_) {
      this.$router.push({
        name: "org-edit",
        params: {
          id: id_,
        },
      });
    },
    async disableDocument(id_documento, id_estado_fk) {
      //console.log("Hola estoy acá en desable Documents");
      let status = id_estado_fk == "1"? "3" : "1";
      let data_ = {
        id_documento: id_documento,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_modificador: this.$ls.get("user").id_usuario,
        estado: status,
      }
      //console.log("Revisando cambiador de estado documents: ", data_);
      await this.cambiaEstadoOrganigrama(data_).then(res => {
        this.show_spinner = false;
          if(!res) {
            this.$toast.open({
              message: "Ocurrio un problema, al intentar activar/deshabilitar un organigrama, favor contactar al administrador",
              type: "error",
              duration: 0,
              position: "top-right",
            });
          }
      });
    },
    deleteDocument(id_org) {
      console.log("Para eliminar un organigrama");
      this.$emit("deleteDocument", id_org);
    },
  },
};
</script>