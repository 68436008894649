<template>
  <section id="organigrama">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <font-awesome-icon 
            icon="sitemap" 
            class="color-secondary"
          />
          Publica un nuevo Organigrama
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/organigrama/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Agregar
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-9 mb-3 mb-lg-0 input-search">
        <input 
            type="search" 
            placeholder="Buscar organigrama"
            v-model="nombre"
            class="form-control input-custom"
            @keyup.enter="searchByName()">
            <span class="input-search-icon">
              <font-awesome-icon icon="search"/>
            </span>
      </div>
      <div class="col-12 col-lg-3 mb-3">
        <v-select 
          class="selvue-custom"
          :options="categorias"
          :reduce="categoria => categoria.id_categoria"
          label="nombre"
          v-model="selectedCategoriaEmpresa"
          @input="searchByCompany()"
          placeholder="Empresa"
          :clearable="false"/>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">  
        <Spinner v-show="show_spinner == true"/>
        <OrganigramaTable
          @deleteDocument="deleteDocument"
          :search="search"
          :documentos="documents"/>
          <Question
            v-if="open_question_modal"
            :msg="question_modal_msg"
            :hideCancel="false"
            @cancel="cancelQuestion"
            @accept="acceptQuestion"/>
      </div>
    </div>

    <div class="row">
      <div class="co-12 mt-3 d-flex justify-content-center">
        <paginate
          v-model="pagination.actual_page"
          :page-count="pagination.page_count"
          :page-range="pagination.page_range"
          :click-handler="pagination.click_handler"
          :prev-text="pagination.prev_text"
          :next-text="pagination.next_text"
          :container-class="pagination.container_class"
          :page-class="pagination.page_class"/>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import OrganigramaTable from "./OrganigramaTable.vue";
import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
export default {
  components: {
    OrganigramaTable,
    Spinner,
    Question,
  },
  data() {
    return {
      search_img: require("@/assets/img/Buscador.svg"),
      show_spinner: true,
      search: false,
      open_question_modal: false,
      question_modal_msg: "",
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      id_categoria: '0',
      id_modulo: '17',
      doc_name: '',
      id_platform: '2',
      id_company: this.$ls.get("user").empresa[0].id_empresa,
      id_user: this.$ls.get("user").id_usuario,
      id_to_delete:'',
      selectedCategoriaEmpresa: '0',
      nombre: ""
    };
  },
  computed: {
    ...mapState("organigramaModule", [
      "documents",
      "company",
      "categorias",
    ]),
    ...mapGetters("organigramaModule", ["searchOrganigrama"]),
  },
  mounted() {
    this.getAllOrgs();
    this.getEmpresas();
  },
  methods: {
    ...mapActions("organigramaModule", ["getOrganigramas", "deleteOrganigrama", "getEmpresasCategoria"]),
    async getAllOrgs() {
      let data_org = {
        id_empresa: this.id_company,
        id_categoria: this.selectedCategoriaEmpresa.toString(),
        id_modulo: this.id_modulo,
        plataforma: this.id_platform,
        nombre: this.nombre,
      }
      console.log('data_',data_org);
      await this.getOrganigramas(data_org);
      this.show_spinner = false;
    },
    async getEmpresas(){
      let data_ = {
        id_empresa: this.id_company,
        id_modulo: this.id_modulo
      }
      console.log("data_ get categorias ", data_);
      await this.getEmpresasCategoria(data_);
    },
    searchByName() {
      this.show_spinner = true;
      this.getAllOrgs();
      this.show_spinner = false;
      //this.search = false;
    },
    searchByCompany(){
      this.show_spinner = true;
      this.getAllOrgs();
      this.show_spinner = false;
      //this.search = false;
    },
    deleteDocument(id_) {
      this.id_to_delete = id_
      this.question_modal_msg = "¿Estás seguro de eliminar éste organigrama?";
      this.open_question_modal = true;
    },
    cancelQuestion() {
      this.open_question_modal = false;
      this.id_to_delete = '';
    },
    async acceptQuestion() {
      this.open_question_modal = false;
      this.show_spinner = true;
      let data_ = {
        id_documento: this.id_to_delete,
        id_empresa: this.id_company,
        id_eliminador: this.id_user
      }
      console.log('data for delete', data_)
      await this.deleteOrganigrama(data_).then(res => {
        this.show_spinner = false
        if (res != null) {
          this.$toast.open({
            message: "Tu organigrama ha sido eliminado correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        } else {
          this.$toast.open({
            message: "No ha sido posible eliminar tu organigrama, intentalo nuevamente.",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
      });
    
    },
  },
  watch: {
    nombre(value) {
      if(value !== '') {
        this.search = true;
      }
    }
  }
};
</script>